import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Eye, EyeOff } from "lucide-react";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithPopup,
  GoogleAuthProvider,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
  updateProfile,
} from "firebase/auth";
import { auth } from "./firebase";
import Carousel from "./Carousel";
import image109 from "../assets/image109.png"; // Import Certification 1
import image110 from "../assets/image110.png"; // Import Certification 2
import image111 from "../assets/image111.png";
import { Flex } from "antd";

// App identifier to distinguish this app from others using same Firebase account
const APP_IDENTIFIER = "file_upload_app";
const SESSION_EXPIRY_MINUTES = 10;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if we have a valid session
    const checkSession = () => {
      // Check session storage
      const sessionData = sessionStorage.getItem(`${APP_IDENTIFIER}_session`);

      if (sessionData) {
        try {
          const { expiry, email } = JSON.parse(sessionData);

          // Check if session is still valid
          if (new Date().getTime() < expiry) {
            navigate("/upload", { state: { email } });
          } else {
            // Session expired, clean up
            sessionStorage.removeItem(`${APP_IDENTIFIER}_session`);
          }
        } catch (error) {
          console.error("Session parsing error:", error);
          sessionStorage.removeItem(`${APP_IDENTIFIER}_session`);
        }
      }

      // Return empty function since we don't need unsubscribe anymore
      return () => {};
    };

    const unsubscribe = checkSession();
    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [navigate]);

  // Function to create a session with expiry
  const createSession = (userEmail) => {
    const expiryTime =
      new Date().getTime() + SESSION_EXPIRY_MINUTES * 60 * 1000;

    // Store session info in sessionStorage
    sessionStorage.setItem(
      `${APP_IDENTIFIER}_session`,
      JSON.stringify({
        email: userEmail,
        expiry: expiryTime,
        appId: APP_IDENTIFIER,
      })
    );

    // Optionally set a custom claim or user profile info to differentiate this app
    if (auth.currentUser) {
      updateProfile(auth.currentUser, {
        displayName: auth.currentUser.displayName || userEmail,
        photoURL:
          auth.currentUser.photoURL ||
          `${APP_IDENTIFIER}:${new Date().getTime()}`,
      }).catch((err) => console.error("Error updating profile:", err));
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    resetErrors();
    try {
      const persistence = rememberMe
        ? browserLocalPersistence
        : browserSessionPersistence;
      await setPersistence(auth, persistence);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Create session with expiry time
      createSession(email);

      navigate("/upload", { state: { email } });
    } catch (err) {
      handleFirebaseError(err.code);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    resetErrors();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Create session with expiry time
      createSession(email);

      navigate("/upload", { state: { email } });
    } catch (err) {
      handleFirebaseError(err.code);
    }
  };

  const handleFirebaseError = (errorCode) => {
    switch (errorCode) {
      case "auth/invalid-email":
        setEmailError("Invalid Email");
        break;
      case "auth/wrong-password":
        setPasswordError("Invalid Password");
        break;
      case "auth/user-not-found":
        setEmailError("User not found");
        break;
      case "auth/weak-password":
        setPasswordError("Password should be at least 6 characters");
        break;
      case "auth/invalid-credential":
        setEmailError("Invalid Email or Password");
        setPasswordError("Invalid Email or Password");
        break;
      default:
        setError("An unexpected error occurred. Please try again.");
        break;
    }
  };

  const resetErrors = () => {
    setEmailError("");
    setPasswordError("");
    setError("");
  };

  const handleGoogleSignIn = async () => {
    resetErrors();
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    try {
      const result = await signInWithPopup(auth, provider);

      // Create session with expiry time
      createSession(result.user.email);

      navigate("/upload", { state: { email: auth.currentUser.email } });
    } catch (err) {
      setError(err.message);
    }
  };

  const handlePasswordReset = async () => {
    resetErrors();
    if (!email) {
      setEmailError("Please provide an email address.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setError("Password reset email sent. Please check your inbox.");
    } catch (err) {
      setEmailError(err.message);
    }
  };

  const handleGuestLogin = async () => {
    resetErrors();
    try {
      await signInAnonymously(auth);

      // Create session with expiry time for anonymous user
      // createSession("Guest User");

      navigate("/upload");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="main-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="235"
        height="51"
        viewBox="0 0 235 51"
        fill="none"
        className="svgicon"
        style={{
          position: "absolute",
          top: "4%",
          left: "2%",
          paddingLeft: "28px",
          zIndex: "100",
        }}
      >
        <path
          d="M21.0208 34.2886H16.1038V13.8262H7.50808V34.2886H2.57733V13.8262H0V10.3217H2.57733V9.77966C2.57733 8.91156 2.57733 7.77706 2.66873 6.95948C3.12113 2.50414 6.06404 0.000887425 12.9826 0.000887425C14.0293 -0.0100823 15.0745 0.0806713 16.1038 0.271882V4.0015C10.0443 3.54219 8.00618 4.54809 7.64517 7.2167C7.5532 7.97872 7.50741 8.74565 7.50808 9.51326V10.3308H21.0208V34.2886Z"
          fill="#03A5B7"
        />
        <path
          d="M48.8207 20.6859V34.2886H43.8411V21.2502C43.8411 20.117 43.8411 19.071 43.7497 17.846C43.5256 15.1209 42.0761 13.2124 38.6832 13.2124C35.2904 13.2124 32.4325 15.9375 32.4325 23.4752V34.2886H27.4575V10.1707H32.4325V14.1666L34.0649 11.414C35.9671 9.95964 37.723 9.55133 40.1282 9.55133C45.2906 9.55133 48.2308 12.2306 48.6835 16.4559C48.7749 17.6625 48.8207 18.7544 48.8207 20.6859Z"
          fill="#03A5B7"
        />
        <path
          d="M85.1812 9.80549V10.7231C80.847 10.3561 77.8177 13.3109 77.8177 21.2119V34.2884H76.8276V10.3928H77.8177V15.527L78.7666 12.8016C79.9204 10.9274 81.9492 9.77904 84.141 9.75961C84.4883 9.75086 84.8359 9.76619 85.1812 9.80549Z"
          fill="#A3A6B7"
        />
        <path
          d="M103.455 28.4244L102.41 30.8329C100.734 33.5305 97.9216 34.9068 94.7016 34.9068C90.1671 34.9068 87.373 32.7735 87.373 28.4841C87.373 22.9009 94.4497 20.4924 101.884 20.4924H103.427V19.3087C103.427 18.171 103.427 17.2626 103.336 16.2167C103.019 12.6291 100.661 10.7115 96.7582 10.7115C93.4054 10.7115 89.8235 12.4823 89.2785 16.9323H88.28C88.5548 12.9365 92.045 9.75723 96.7582 9.75723C101.471 9.75723 104.013 12.0511 104.334 16.0698C104.426 17.2948 104.426 17.9324 104.426 19.2032V26.5435C104.426 29.2685 104.558 31.1311 104.833 34.2645H103.789L103.455 28.4244ZM103.409 24.791V21.5246C96.4238 21.0704 88.4586 23.0202 88.4586 28.4841C88.4586 31.8009 90.4968 33.9893 94.8711 33.9893C99.9598 33.9893 103.409 29.5163 103.409 24.8139V24.791Z"
          fill="#A3A6B7"
        />
        <path
          d="M112.288 6.79732V10.6308H117.124V11.5857H112.288V34.3571H111.31V11.572H108.734V10.6308H111.31V6.79732C111.3 5.99439 111.33 5.19144 111.402 4.39163C111.671 1.38911 113.685 0.0714961 115.512 0.0714961C116.227 0.0519306 116.941 0.12914 117.636 0.301046V1.25138C114.11 0.388276 112.612 2.11909 112.393 4.52477C112.318 5.27992 112.283 6.03848 112.288 6.79732Z"
          fill="#A3A6B7"
        />
        <path
          d="M127.908 33.6955V34.4942C127.147 34.5927 126.381 34.6387 125.614 34.6319C123.48 34.6319 122.346 33.6313 122.163 31.5427C122.071 30.4502 122.071 29.679 122.071 28.3294V11.4279H119.212V10.6246H122.071V5.70374H123.072V10.6246H127.775V11.4416H123.094V28.5268C123.094 29.8029 123.094 30.4823 123.14 31.1203C123.319 33.466 124.366 34.1959 127.908 33.6955Z"
          fill="#A3A6B7"
        />
        <path
          d="M134.686 32.7884C134.695 33.6679 134.173 34.4656 133.365 34.8064C132.557 35.1473 131.624 34.9636 131.004 34.3417C130.384 33.7198 130.201 32.7833 130.541 31.9725C130.881 31.1617 131.675 30.6379 132.552 30.6472C133.122 30.6345 133.672 30.856 134.075 31.2603C134.477 31.6647 134.698 32.2167 134.686 32.7884Z"
          fill="#03A5B7"
        />
        <path
          d="M165.5 6.83989C165.506 7.39403 165.158 7.89661 164.62 8.11139C164.082 8.32617 163.46 8.21043 163.047 7.81857C162.634 7.42671 162.513 6.83667 162.739 6.32581C162.965 5.81495 163.495 5.48488 164.078 5.49074C164.458 5.48274 164.824 5.6223 165.093 5.87707C165.361 6.13184 165.508 6.47968 165.5 6.83989Z"
          fill="#A3A6B7"
        />
        <path
          d="M164.602 33.9462L164.602 34.4149H164.106H163.539C163.539 34.4149 163.539 29.4938 163.539 28.2045V12.0576V11.5804H163.696H163.963H164.573V12.0708V28.3931C164.573 29.6122 164.555 33.1023 164.602 33.7119V33.9462Z"
          fill="#A3A6B7"
        />
        <path
          d="M154.991 28.3845L153.94 30.7717C152.254 33.4453 149.425 34.8094 146.186 34.8094C141.624 34.8094 138.814 32.6951 138.814 28.4436C138.814 22.9099 145.932 20.5228 153.41 20.5228H154.963V19.3496C154.963 18.222 154.963 17.3217 154.871 16.285C154.553 12.7292 152.18 10.8286 148.254 10.8286C144.882 10.8286 141.279 12.5837 140.73 16.9943H139.726C140.002 13.0339 143.513 9.88279 148.254 9.88279C152.996 9.88279 155.553 12.1563 155.875 16.1395C155.967 17.3535 155.967 17.9855 155.967 19.245V26.5202C155.967 29.2211 156.107 31.5199 156.383 34.6255H155.318L154.991 28.3845ZM154.945 24.7833V21.5458C147.918 21.0957 139.906 23.0281 139.906 28.4436C139.906 31.7311 141.956 33.9 146.356 33.9C151.475 33.9 154.945 29.4667 154.945 24.806V24.7833Z"
          fill="#A3A6B7"
        />
        <path
          d="M56.383 24.3728C56.3621 24.3918 56.3502 24.4187 56.3502 24.4469V33.9405C56.3502 33.9957 56.3054 34.0405 56.2502 34.0405H55.4594C55.4041 34.0405 55.3594 33.9957 55.3594 33.9405V1.19782C55.3594 1.14259 55.4041 1.09782 55.4594 1.09782H56.2502C56.3054 1.09782 56.3502 1.14259 56.3502 1.19782V23.012C56.3502 23.099 56.4536 23.1445 56.5177 23.0857L70.2704 10.4903C70.2888 10.4735 70.3129 10.4641 70.3379 10.4641H71.5262C71.6177 10.4641 71.6611 10.5767 71.5934 10.6381L59.2657 21.8227C59.2228 21.8616 59.2218 21.9287 59.2636 21.9689L71.6515 33.8684C71.7165 33.9307 71.6723 34.0405 71.5822 34.0405H70.3847C70.3589 34.0405 70.3341 34.0305 70.3155 34.0127L58.4446 22.6292C58.4067 22.5928 58.3471 22.592 58.3082 22.6273L56.383 24.3728Z"
          fill="#03A5B7"
        />
        <path
          d="M72.3437 13.1978L63.0362 21.9733C63.0046 22.0031 63.0039 22.053 63.0345 22.0837L63.4012 22.4506L72.3784 31.304C72.3928 31.3182 72.4122 31.3262 72.4325 31.3262H73.3592C73.4278 31.3262 73.4621 31.2433 73.4136 31.1948L64.3022 22.0836C64.2716 22.0531 64.2722 22.0034 64.3034 21.9735L73.3712 13.3095C73.4214 13.2615 73.3875 13.1768 73.318 13.1768H72.3965C72.3769 13.1768 72.358 13.1843 72.3437 13.1978Z"
          fill="#A3A6B7"
        />
      </svg>
      <div className="image-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="235"
          height="51"
          viewBox="0 0 235 51"
          fill="none"
          className="svgicon"
        >
          {/* ...existing SVG path... */}
        </svg>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            maxWidth: "90%",
          }}
        >
          <Carousel />
          <div
            className="certification-container"
            style={{
              marginTop: "20%",
            }}
          >
            <img
              style={{
                maxWidth: "10%",
              }}
              src={image109}
              alt="Certification 1"
              className="certification-logo"
            />
            <img
              style={{
                maxWidth: "20%",
              }}
              src={image110}
              alt="Certification 2"
              className="certification-logo"
            />
            <img
              style={{
                maxWidth: "10%",
              }}
              src={image111}
              alt="Certification 3"
              className="certification-logo"
            />
          </div>
        </div>
      </div>

      {/* Right side form container */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",

          borderRadius: "1.25rem",
          padding: "2vw 1.5rem",
          minHeight: "95%",
          minWidth: "30%",
          width: "80%",
          maxWidth: "30rem",
        }}
      >
        <div
          style={{
            width: "28rem",
            maxWidth: "28rem",
            margin: "0 auto",
            backgroundColor: "#FFFFFF",
            padding: "1.5rem",
            borderRadius: "0.5rem",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div style={{ marginBottom: "2rem" }}>
            <h1
              style={{ color: "#4B5563", fontWeight: 500, fontSize: "1.25rem" }}
            >
              {isRegistering ? "Welcome," : "Welcome back,"}
            </h1>
            <h6
              style={{
                fontWeight: "400",
                color: "#1F2937",
              }}
            >
              {isRegistering ? "Register" : "Login"}
            </h6>
          </div>

          {error && (
            <div
              style={{
                marginBottom: "1rem",
                color: "#DC2626",
                fontSize: "0.875rem",
              }}
            >
              {error}
            </div>
          )}

          <form
            onSubmit={isRegistering ? handleRegister : handleLogin}
            style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
          >
            <div>
              <label
                style={{
                  display: "block",
                  fontSize: "0.875rem",
                  color: "#4B5563",
                  marginBottom: "0.5rem",
                }}
              >
                Email
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  width: "100%",
                  padding: "0.5rem 1rem",
                  border: "1px solid #D1D5DB",
                  borderRadius: "0.375rem",
                  outline: "none",
                  boxSizing: "border-box",
                }}
                placeholder="Enter email address"
                required
              />
              {emailError && (
                <p
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "0.875rem",
                    color: "#DC2626",
                  }}
                >
                  {emailError}
                </p>
              )}
            </div>

            <div>
              <label
                style={{
                  display: "block",
                  fontSize: "0.875rem",
                  color: "#4B5563",
                  marginBottom: "0.5rem",
                }}
              >
                Password
              </label>
              <div style={{ position: "relative" }}>
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "0.5rem 1rem",
                    border: "1px solid #D1D5DB",
                    borderRadius: "0.375rem",
                    outline: "none",
                    boxSizing: "border-box",
                  }}
                  placeholder="Enter password"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: "absolute",
                    right: "0.75rem",
                    top: "50%",
                    transform: "translateY(-50%)",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? (
                    <EyeOff
                      style={{
                        width: "1.25rem",
                        height: "1.25rem",
                        color: "#6B7280",
                      }}
                    />
                  ) : (
                    <Eye
                      style={{
                        width: "1.25rem",
                        height: "1.25rem",
                        color: "#6B7280",
                      }}
                    />
                  )}
                </button>
              </div>
              {passwordError && (
                <p
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "0.875rem",
                    color: "#DC2626",
                  }}
                >
                  {passwordError}
                </p>
              )}
            </div>

            {!isRegistering && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
               
                <button
                  type="button"
                  onClick={handlePasswordReset}
                  style={{
                    fontSize: "0.875rem",
                    color: "#0A8394",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Forgot password?
                </button>
              </div>
            )}

            <button
              type="submit"
              style={{
                width: "100%",
                backgroundColor: "#0A8394",
                color: "#FFFFFF",
                padding: "0.5rem 1rem",
                borderRadius: "0.375rem",
                cursor: "pointer",
                transition: "background-color 0.2s",
                boxShadow: "none", // Added to remove box shadow
              }}
            >
              {isRegistering ? "Register" : "Continue"}
            </button>
          </form>

          <div
            style={{
              marginTop: "1.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{ flexGrow: 1, height: "1px", backgroundColor: "#D1D5DB" }}
            ></div>
            <span
              style={{
                padding: "0 1rem",
                fontSize: "0.875rem",
                color: "#6B7280",
              }}
            >
              Or
            </span>
            <div
              style={{ flexGrow: 1, height: "1px", backgroundColor: "#D1D5DB" }}
            ></div>
          </div>

          <button
            onClick={handleGuestLogin}
            style={{
              marginTop: "1.5rem",
              width: "100%",
              border: "1px solid #0A8394",
              color: "#0A8394",
              padding: "0.5rem 1rem",
              borderRadius: "0.375rem",
              cursor: "pointer",
              backgroundColor: "#FFFFFF",
              transition: "background-color 0.2s",
            }}
          >
            Continue as guest
          </button>

          <button
            onClick={handleGoogleSignIn}
            style={{
              marginTop: "1rem",
              width: "100%",
              border: "1px solid #D1D5DB",
              backgroundColor: "#FFFFFF",
              color: "#4B5563",
              padding: "0.5rem 1rem",
              borderRadius: "0.375rem",
              cursor: "pointer",
              transition: "background-color 0.2s",
            }}
          >
            <div
              style={{
                display: "Flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: "3%" }}
              >
                <path
                  d="M17.64 9.205c0-.639-.057-1.252-.164-1.841H9v3.481h4.844a4.14 4.14 0 0 1-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.615z"
                  fill="#4285F4"
                />
                <path
                  d="M9 18c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H.957v2.332A8.997 8.997 0 0 0 9 18z"
                  fill="#34A853"
                />
                <path
                  d="M3.964 10.71A5.41 5.41 0 0 1 3.682 9c0-.593.102-1.17.282-1.71V4.958H.957A8.996 8.996 0 0 0 0 9c0 1.452.348 2.827.957 4.042l3.007-2.332z"
                  fill="#FBBC05"
                />
                <path
                  d="M9 3.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C13.463.891 11.426 0 9 0A8.997 8.997 0 0 0 .957 4.958L3.964 7.29C4.672 5.163 6.656 3.58 9 3.58z"
                  fill="#EA4335"
                />
              </svg>
              Sign in with Google
            </div>
          </button>

          <p
            style={{
              marginTop: "1.5rem",
              textAlign: "center",
              fontSize: "0.875rem",
              color: "#4B5563",
            }}
          >
            {isRegistering
              ? "Already have an account?"
              : "Don't have an account?"}{" "}
            <button
              type="button"
              onClick={() => setIsRegistering(!isRegistering)}
              style={{
                color: "#0A8394",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              {isRegistering ? "Login" : "Sign up"}
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
