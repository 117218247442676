import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "./firebase";
import Carousel from "./Carousel"; // Ensure the path to firebase.js is correct
import "../style/FileUpload.css";
import { X, CloudUpload, ArrowUp, ArrowLeft, LogOut } from "lucide-react";
import FrameImage from "../assets/Frame.png";
import GroupImage from "../assets/Group 1218.png";
import shieldTickSafe from "../assets/shield-tick-safe.svg";
// import img from "../assets/Frame.png";
import img from "../assets/mainpage.lottie";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import mainPageLottie from "../assets/mainpage.lottie";
import {
  Modal,
  Button,
  Input,
  Checkbox,
  Spin,
  Typography,
  Divider,
  Tooltip,
  Progress,
  Avatar,
} from "antd"; // Import Ant Design Modal, Button, Input, Checkbox, Spin, Divider, Tooltip, Progress, and Avatar
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import Toast styles
import { InfoCircleOutlined, CloseOutlined } from "@ant-design/icons"; // Import Ant Design InfoCircleOutlined and CloseOutlined icons
import JSZip from "jszip"; // Import JSZip for file compression
import { motion } from "framer-motion"; // Import Framer Motion for animations
import { relative } from "path-browserify";
import { v4 as uuidv4 } from "uuid"; // Import UUID library
import image109 from "../assets/image109.png"; // Import Certification 1
import image110 from "../assets/image110.png"; // Import Certification 2
import image111 from "../assets/image111.png"; // Import Certification 3
import { UserCircle } from "lucide-react"; // Add this import for profile icon

const { Title, Text, Paragraph } = Typography; // Destructure Typography components

const FileUpload = () => {
  const location = useLocation();
  const email = location.state?.email || "";
  const [files, setFiles] = useState([]); // State for multiple files
  const [fileCount, setFileCount] = useState(0); // State for file count
  const [url, setUrl] = useState("");
  const [error, setError] = useState("");
  const [bloburl, setblobUrl] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
  const [countdown, setCountdown] = useState(3); // State for countdown timer
  const [clientName, setClientName] = useState(""); // State for client name
  const [compress, setCompress] = useState(false); // State for compression checkbox
  const [isZipping, setIsZipping] = useState(false); // State for zipping process
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false); // added
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false); // New state for showing progress bar
  const [uploadStatus, setUploadStatus] = useState("uploading"); // New state for upload status
  const navigate = useNavigate();

  useEffect(() => {
    if (email) {
      console.log("User email:", email);
    }
  }, [email]);

  useEffect(() => {
    if (error) {
      toast.dismiss();
      toast.error(error, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setError("");
    }
  }, [error]);

  const handleFileChange = (e) => {
    const selectedFiles = [...e.target.files];
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    setFileCount((prevCount) => prevCount + selectedFiles.length); // Update file count
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.classList.add("drag-over");
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.classList.remove("drag-over");
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.classList.remove("drag-over");
    const droppedFiles = [...e.dataTransfer.files];
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    setFileCount((prevCount) => prevCount + droppedFiles.length); // Update file count
  };

  const handleDragDropClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    setFileCount(updatedFiles.length); // Update file count
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setError("Please select one or more files to upload.");
      return;
    }

    if (!email && !clientName) {
      toast.dismiss();
      toast.error("Please provide a client name.", { autoClose: 2000 });
      return;
    }

    try {
      setError("");
      setUploadProgress(0); // Reset progress
      setShowProgressBar(true); // Show in-card progress bar
      setUploadStatus("uploading"); // Set status to uploading

      const formData = new FormData();
      const uuid = uuidv4();
      if (compress) {
        setIsZipping(true);
        const zip = new JSZip();
        files.forEach((file) => {
          zip.file(file.name, file);
        });
        const zipBlob = await zip.generateAsync({ type: "blob" });
        formData.append("files", zipBlob, `${uuid}_compressed.zip`);
        setIsZipping(false);
      } else {
        files.forEach((file) => {
          formData.append("files", file, `${uuid}_${file.name}`);
        });
      }
      formData.append("email", email || clientName);

      var upload_URL = "https://files-upload.finkraftai.com/upload";
      // setIsModalVisible(true);
      toast.dismiss(); // Dismiss any existing toasts

      axios
        .post(upload_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        })
        .then((response) => {
          const fileurl = response.data.files_info[0][1];
          console.log(fileurl);
          const parts = fileurl.split("/");
          const croppedPath = `/${parts[4]}`;
          setblobUrl(croppedPath);
          setIsModalVisible(true);
          setUploadStatus("success"); // Set status to success
          console.log("File uploaded successfully");
          setUploadProgress(100);

          // We're only keeping the success toast notification
        })
        .catch((err) => {
          console.error("Upload failed:", err);
          setError("Upload failed. Please try again.");
          setUploadStatus("error"); // Set status to error
          toast.dismiss();
          toast.error("Upload Failed", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          // Show error modal with similar styling as success modal
          setblobUrl(""); // Clear the blob URL
          setIsModalVisible(true); // Use the same modal
        });
    } catch (err) {
      console.error("Upload failed:", err);
      setIsModalVisible(false);
      setShowProgressBar(false); // Hide progress bar on error
      toast.dismiss();
      setError("Upload failed. Please try again.");
      setIsZipping(false);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/"); // Navigate back to the login page
    } catch (err) {
      setError("Failed to log out. Please try again.");
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setShowProgressBar(false); // Hide progress bar when modal is closed
    // Refresh the page on modal close
    window.location.reload();
  };

  const handleBackClick = () => {
    // Changed to use browser back action instead of logout
    window.history.back();
  };

  const confirmLogout = async () => {
    // added
    try {
      // await signOut(auth);
      sessionStorage.removeItem(`file_upload_app_session`);
      navigate("/"); // Navigate back to the login page
    } catch (err) {
      setError("Failed to log out. Please try again.");
    }
  };

  const cancelLogout = () => {
    // added
    setIsLogoutModalVisible(false);
  };

  return (
    <div className="main-container">
      {/* Profile section with logout button */}
      <div
        style={{
          position: "absolute",
          top: "1rem",
          right: "1rem",
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          backgroundColor: "#F8FAFC",
          padding: "0.5rem 1rem",
          borderRadius: "2rem",
          boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
          zIndex: 10,
        }}
      >
        <UserCircle size={24} color="#0A8394" />
        <Text style={{ color: "#475569", fontSize: "0.875rem" }}>
          {email || "Guest"}
        </Text>
        <div
          onClick={() => setIsLogoutModalVisible(true)}
          style={{
            marginLeft: "0.5rem",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            padding: "0.25rem 0.5rem",
            borderRadius: "1rem",
            transition: "background-color 0.2s ease",
            border: "1px solid #E2E8F0",
            backgroundColor: "#FFFFFF",
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor = "#E2E8F0")
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = "#FFFFFF")
          }
        >
          <LogOut
            size={16}
            color="#475569"
            style={{ marginRight: "0.25rem" }}
          />
          <span style={{ fontSize: "0.75rem", color: "#475569" }}>Logout</span>
        </div>
      </div>
      <ToastContainer /> {/* Toast container for notifications */}
      <Modal
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: bloburl ? "#0A8394" : "#E53E3E",
              gap: "10px",
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            <div
              style={{
                backgroundColor: bloburl ? "#E6F7F8" : "#FEE2E2",
                borderRadius: "50%",
                width: "28px",
                height: "28px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {bloburl ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#0A8394"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                  <polyline points="22 4 12 14.01 9 11.01"></polyline>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#E53E3E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="15" y1="9" x2="9" y2="15"></line>
                  <line x1="9" y1="9" x2="15" y2="15"></line>
                </svg>
              )}
            </div>
            {bloburl ? "Upload Successful" : "Upload Failed"}
          </div>
        }
        visible={isModalVisible}
        onOk={handleOk}
        // Trigger refresh on close
        footer={[
          <Button
            key="ok"
            type="primary"
            onClick={handleOk}
            style={{
              backgroundColor: bloburl ? "#0A8394" : "#E53E3E",
              borderColor: bloburl ? "#0A8394" : "#E53E3E",
            }}
          >
            {bloburl ? "Done" : "Try Again"}
          </Button>,
        ]}
      >
        <div
          style={{
            padding: "10px 0",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          {bloburl ? (
            // Success content
            <>
              <div
                style={{
                  backgroundColor: "#F8FAFC",
                  padding: "15px",
                  borderRadius: "8px",
                  border: "1px solid #E2E8F0",
                }}
              >
                <p
                  style={{
                    color: "#475569",
                    marginBottom: "5px",
                    fontWeight: 500,
                  }}
                >
                  Upload Complete
                </p>
                <p style={{ color: "#64748B", margin: 0, fontSize: "0.9rem" }}>
                  Your file has been uploaded successfully
                </p>

                <p
                  style={{
                    color: "#0A8394",
                    margin: 0,
                    fontSize: "0.9rem",
                    wordWrap: "break-word",
                  }}
                >
                  {bloburl}
                </p>
              </div>
            </>
          ) : (
            // Error content
            <>
              <div
                style={{
                  backgroundColor: "#FEF2F2",
                  padding: "15px",
                  borderRadius: "8px",
                  border: "1px solid #FEE2E2",
                }}
              >
                <p
                  style={{
                    color: "#B91C1C",
                    marginBottom: "5px",
                    fontWeight: 500,
                  }}
                >
                  Upload Failed
                </p>
                <p style={{ color: "#7F1D1D", margin: 0, fontSize: "0.9rem" }}>
                  We couldn't complete your file upload
                </p>
              </div>

              <div
                style={{
                  backgroundColor: "#F8FAFC",
                  padding: "15px",
                  borderRadius: "8px",
                  border: "1px solid #E2E8F0",
                }}
              >
                <p
                  style={{
                    color: "#475569",
                    marginBottom: "5px",
                    fontWeight: 500,
                  }}
                >
                  Possible Reasons
                </p>
                <ul
                  style={{
                    color: "#64748B",
                    margin: "0",
                    paddingLeft: "20px",
                    fontSize: "0.9rem",
                  }}
                >
                  <li>Network connection issues</li>
                  <li>Server is currently unavailable</li>
                  <li>File size exceeds the maximum limit</li>
                </ul>
              </div>

              <div
                style={{
                  backgroundColor: "#F8FAFC",
                  padding: "15px",
                  borderRadius: "8px",
                  border: "1px solid #E2E8F0",
                }}
              >
                <p
                  style={{
                    color: "#475569",
                    marginBottom: "5px",
                    fontWeight: 500,
                  }}
                >
                  What to do?
                </p>
                <p style={{ color: "#64748B", margin: 0, fontSize: "0.9rem" }}>
                  Please check your connection and try again. If the problem
                  persists, contact support for assistance.
                </p>
              </div>
            </>
          )}
        </div>
      </Modal>
      {/* Logout Confirmation Modal */}
      <Modal
        title="Confirm Logout"
        visible={isLogoutModalVisible}
        onOk={confirmLogout}
        onCancel={cancelLogout}
        footer={[
          <Button key="cancel" onClick={cancelLogout}>
            Cancel
          </Button>,
          <Button
            key="confirm"
            type="primary"
            onClick={confirmLogout}
            style={{ backgroundColor: "#008080", borderColor: "#008080" }}
          >
            Yes, Logout
          </Button>,
        ]}
      >
        <Paragraph>Are you sure you want to logout?</Paragraph>
      </Modal>
      <div className="image-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="235"
          height="51"
          viewBox="0 0 235 51"
          fill="none"
          className="svgicon"
        >
          <path
            d="M21.0208 34.2886H16.1038V13.8262H7.50808V34.2886H2.57733V13.8262H0V10.3217H2.57733V9.77966C2.57733 8.91156 2.57733 7.77706 2.66873 6.95948C3.12113 2.50414 6.06404 0.000887425 12.9826 0.000887425C14.0293 -0.0100823 15.0745 0.0806713 16.1038 0.271882V4.0015C10.0443 3.54219 8.00618 4.54809 7.64517 7.2167C7.5532 7.97872 7.50741 8.74565 7.50808 9.51326V10.3308H21.0208V34.2886Z"
            fill="#03A5B7"
          />
          <path
            d="M48.8207 20.6859V34.2886H43.8411V21.2502C43.8411 20.117 43.8411 19.071 43.7497 17.846C43.5256 15.1209 42.0761 13.2124 38.6832 13.2124C35.2904 13.2124 32.4325 15.9375 32.4325 23.4752V34.2886H27.4575V10.1707H32.4325V14.1666L34.0649 11.414C35.9671 9.95964 37.723 9.55133 40.1282 9.55133C45.2906 9.55133 48.2308 12.2306 48.6835 16.4559C48.7749 17.6625 48.8207 18.7544 48.8207 20.6859Z"
            fill="#03A5B7"
          />
          <path
            d="M85.1812 9.80549V10.7231C80.847 10.3561 77.8177 13.3109 77.8177 21.2119V34.2884H76.8276V10.3928H77.8177V15.527L78.7666 12.8016C79.9204 10.9274 81.9492 9.77904 84.141 9.75961C84.4883 9.75086 84.8359 9.76619 85.1812 9.80549Z"
            fill="#A3A6B7"
          />
          <path
            d="M103.455 28.4244L102.41 30.8329C100.734 33.5305 97.9216 34.9068 94.7016 34.9068C90.1671 34.9068 87.373 32.7735 87.373 28.4841C87.373 22.9009 94.4497 20.4924 101.884 20.4924H103.427V19.3087C103.427 18.171 103.427 17.2626 103.336 16.2167C103.019 12.6291 100.661 10.7115 96.7582 10.7115C93.4054 10.7115 89.8235 12.4823 89.2785 16.9323H88.28C88.5548 12.9365 92.045 9.75723 96.7582 9.75723C101.471 9.75723 104.013 12.0511 104.334 16.0698C104.426 17.2948 104.426 17.9324 104.426 19.2032V26.5435C104.426 29.2685 104.558 31.1311 104.833 34.2645H103.789L103.455 28.4244ZM103.409 24.791V21.5246C96.4238 21.0704 88.4586 23.0202 88.4586 28.4841C88.4586 31.8009 90.4968 33.9893 94.8711 33.9893C99.9598 33.9893 103.409 29.5163 103.409 24.8139V24.791Z"
            fill="#A3A6B7"
          />
          <path
            d="M112.288 6.79732V10.6308H117.124V11.5857H112.288V34.3571H111.31V11.572H108.734V10.6308H111.31V6.79732C111.3 5.99439 111.33 5.19144 111.402 4.39163C111.671 1.38911 113.685 0.0714961 115.512 0.0714961C116.227 0.0519306 116.941 0.12914 117.636 0.301046V1.25138C114.11 0.388276 112.612 2.11909 112.393 4.52477C112.318 5.27992 112.283 6.03848 112.288 6.79732Z"
            fill="#A3A6B7"
          />
          <path
            d="M127.908 33.6955V34.4942C127.147 34.5927 126.381 34.6387 125.614 34.6319C123.48 34.6319 122.346 33.6313 122.163 31.5427C122.071 30.4502 122.071 29.679 122.071 28.3294V11.4279H119.212V10.6246H122.071V5.70374H123.072V10.6246H127.775V11.4416H123.094V28.5268C123.094 29.8029 123.094 30.4823 123.14 31.1203C123.319 33.466 124.366 34.1959 127.908 33.6955Z"
            fill="#A3A6B7"
          />
          <path
            d="M134.686 32.7884C134.695 33.6679 134.173 34.4656 133.365 34.8064C132.557 35.1473 131.624 34.9636 131.004 34.3417C130.384 33.7198 130.201 32.7833 130.541 31.9725C130.881 31.1617 131.675 30.6379 132.552 30.6472C133.122 30.6345 133.672 30.856 134.075 31.2603C134.477 31.6647 134.698 32.2167 134.686 32.7884Z"
            fill="#03A5B7"
          />
          <path
            d="M165.5 6.83989C165.506 7.39403 165.158 7.89661 164.62 8.11139C164.082 8.32617 163.46 8.21043 163.047 7.81857C162.634 7.42671 162.513 6.83667 162.739 6.32581C162.965 5.81495 163.495 5.48488 164.078 5.49074C164.458 5.48274 164.824 5.6223 165.093 5.87707C165.361 6.13184 165.508 6.47968 165.5 6.83989Z"
            fill="#A3A6B7"
          />
          <path
            d="M164.602 33.9462L164.602 34.4149H164.106H163.539C163.539 34.4149 163.539 29.4938 163.539 28.2045V12.0576V11.5804H163.696H163.963H164.573V12.0708V28.3931C164.573 29.6122 164.555 33.1023 164.602 33.7119V33.9462Z"
            fill="#A3A6B7"
          />
          <path
            d="M154.991 28.3845L153.94 30.7717C152.254 33.4453 149.425 34.8094 146.186 34.8094C141.624 34.8094 138.814 32.6951 138.814 28.4436C138.814 22.9099 145.932 20.5228 153.41 20.5228H154.963V19.3496C154.963 18.222 154.963 17.3217 154.871 16.285C154.553 12.7292 152.18 10.8286 148.254 10.8286C144.882 10.8286 141.279 12.5837 140.73 16.9943H139.726C140.002 13.0339 143.513 9.88279 148.254 9.88279C152.996 9.88279 155.553 12.1563 155.875 16.1395C155.967 17.3535 155.967 17.9855 155.967 19.245V26.5202C155.967 29.2211 156.107 31.5199 156.383 34.6255H155.318L154.991 28.3845ZM154.945 24.7833V21.5458C147.918 21.0957 139.906 23.0281 139.906 28.4436C139.906 31.7311 141.956 33.9 146.356 33.9C151.475 33.9 154.945 29.4667 154.945 24.806V24.7833Z"
            fill="#A3A6B7"
          />
          <path
            d="M56.383 24.3728C56.3621 24.3918 56.3502 24.4187 56.3502 24.4469V33.9405C56.3502 33.9957 56.3054 34.0405 56.2502 34.0405H55.4594C55.4041 34.0405 55.3594 33.9957 55.3594 33.9405V1.19782C55.3594 1.14259 55.4041 1.09782 55.4594 1.09782H56.2502C56.3054 1.09782 56.3502 1.14259 56.3502 1.19782V23.012C56.3502 23.099 56.4536 23.1445 56.5177 23.0857L70.2704 10.4903C70.2888 10.4735 70.3129 10.4641 70.3379 10.4641H71.5262C71.6177 10.4641 71.6611 10.5767 71.5934 10.6381L59.2657 21.8227C59.2228 21.8616 59.2218 21.9287 59.2636 21.9689L71.6515 33.8684C71.7165 33.9307 71.6723 34.0405 71.5822 34.0405H70.3847C70.3589 34.0405 70.3341 34.0305 70.3155 34.0127L58.4446 22.6292C58.4067 22.5928 58.3471 22.592 58.3082 22.6273L56.383 24.3728Z"
            fill="#03A5B7"
          />
          <path
            d="M72.3437 13.1978L63.0362 21.9733C63.0046 22.0031 63.0039 22.053 63.0345 22.0837L63.4012 22.4506L72.3784 31.304C72.3928 31.3182 72.4122 31.3262 72.4325 31.3262H73.3592C73.4278 31.3262 73.4621 31.2433 73.4136 31.1948L64.3022 22.0836C64.2716 22.0531 64.2722 22.0034 64.3034 21.9735L73.3712 13.3095C73.4214 13.2615 73.3875 13.1768 73.318 13.1768H72.3965C72.3769 13.1768 72.358 13.1843 72.3437 13.1978Z"
            fill="#A3A6B7"
          />
        </svg>
        {/* <div className="main-image">
          <DotLottieReact
            src="https://lottie.host/4a1f4269-a942-49ba-a0da-14d8b430f9a3/B9ZwrA9a7w.lottie"
            loop
            autoplay
            style={{ width: "100%", height: "auto", objectFit: "fill" }} // Adjust the style to fit the container
          />
          
        </div> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            maxWidth: "90%",
          }}
        >
          <Carousel />
          <div
            className="certification-container"
            style={{
              marginTop: "20%",
            }}
          >
            {" "}
            <img
              style={{
                maxWidth: "10%",
              }}
              src={image109}
              alt="Certification 1"
              className="certification-logo"
            />{" "}
            <img
              style={{
                maxWidth: "20%",
              }}
              src={image110}
              alt="Certification 2"
              className="certification-logo"
            />{" "}
            <img
              style={{
                maxWidth: "10%",
              }}
              src={image111}
              alt="Certification 3"
              className="certification-logo"
            />{" "}
          </div>
        </div>

        {/* <Title level={1} style={{ "margin-left": "-49%", color: "#0A8394" }}>
          Quick File Upload
        </Title> */}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          backgroundColor: "#FFFFFF",
          borderRadius: "2vw",
          padding: "2vw 1.5rem",
          minHeight: "95%",
          minWidth: "30%",
          width: "80%",
          maxWidth: "30rem",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.05)",
          position: "relative", // Added to position the back icon
        }}
      >
        {/* <div
          onClick={handleBackClick}
          style={{
            position: "absolute", // Change to absolute positioning
            top: "1rem", // Position from the top
            right: "1rem", // Position from the right
            cursor: "pointer", // Change cursor to pointer
          }}
        >
          <ArrowLeft size={24} color="#0A8394" />
        </div> */}
        <div
          style={{
            marginBottom: "1vw",
          }}
        >
          <h1
            style={{
              fontSize: "1.25rem",
              fontWeight: "500",
              marginBottom: "0.25rem",
              color: "#475569",
            }}
          >
            Welcome back,
          </h1>

          <h6
            style={{
              fontWeight: "400",
              color: "#0F172A",
            }}
          >
            File upload service
          </h6>
        </div>

        {/* File Selection Area */}
        <div style={{ marginBottom: "1.5rem" }}>
          <div
            style={{
              border: "2px dashed #CBD5E1",
              borderRadius: "0.5rem",
              padding: "1vw",
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: "#FFFFFF",
              transition: "border-color 0.2s ease",
            }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={() => document.getElementById("fileInput").click()}
            onDragEnter={(e) => {
              e.preventDefault();
              e.currentTarget.style.borderColor = "#0891B2";
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              e.currentTarget.style.borderColor = "#CBD5E1";
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0.25rem",
              }}
            >
              <ArrowUp
                size={16}
                color="#6B7280"
                style={{ marginBottom: "0.5rem" }}
              />
              <span style={{ color: "#64748B" }}>
                Drag & Drop or{" "}
                <span style={{ color: "#0A8394", textDecoration: "underline" }}>
                  Choose file
                </span>{" "}
                to upload
              </span>
              <span style={{ color: "#94A3B8", fontSize: "0.875rem" }}>
                All file formats supported
              </span>
            </div>
          </div>
          <input
            type="file"
            id="fileInput"
            multiple
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <div
            style={{
              fontSize: "0.875rem",
              color: "#64748B",
              marginTop: "0.75rem",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Tooltip
              title="Upload multiple files at once by selecting them or dragging and dropping"
              color="#0A8394"
              overlayInnerStyle={{
                padding: "8px 12px",
                fontSize: "0.813rem",
              }}
            >
              <InfoCircleOutlined
                style={{
                  color: "#0A8394",
                  fontSize: "1rem",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
            Select one or more files
          </div>
        </div>

        {/* Client Name Input */}
        {!email && (
          <div style={{ marginBottom: "1.5rem" }}>
            <label
              style={{
                display: "block",
                color: "#64748B",
                fontSize: "0.875rem",
                marginBottom: "0.5rem",
              }}
            >
              Enter client name
            </label>
            <input
              style={{
                width: "100%",
                padding: "0.4rem 0.75rem",
                border: "1px solid #E2E8F0",
                borderRadius: "0.375rem",
                fontSize: "1rem",
                color: "#0F172A",
                transition: "border-color 0.2s ease",
                outline: "none",
              }}
              placeholder="Enter client name"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              onFocus={(e) => (e.target.style.borderColor = "#0891B2")}
              onBlur={(e) => (e.target.style.borderColor = "#E2E8F0")}
            />
          </div>
        )}

        {/* File List */}
        <div style={{ marginBottom: "1.5rem" }}>
          <label
            style={{
              display: "block",
              color: "#64748B",
              fontSize: "0.875rem",
              marginBottom: "0.5rem",
            }}
          >
            Selected files
          </label>
          <div
            style={{
              backgroundColor: "#F8FAFC",
              borderRadius: "0.375rem",
            }}
          >
            {files.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: showProgressBar ? "1.5rem" : "5rem", // Reduce height when progress bar is visible
                  fontSize: "0.875rem",
                  color: "#64748B",
                }}
              >
                No files selected
              </div>
            ) : (
              !showProgressBar && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: showProgressBar ? "2rem" : "6rem", // Reduce height when progress bar is visible
                    overflowY: "auto",
                    gap: "0.25rem",
                    padding: "0.25rem",
                  }}
                >
                  {files.map((file, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0.3rem 0.75rem",
                        borderRadius: "0.25rem",
                        backgroundColor: "#FFFFFF",
                        transition: "background-color 0.2s ease",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#F1F5F9")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "#FFFFFF")
                      }
                    >
                      <span
                        style={{
                          color: "#0F172A",
                          fontSize: "0.875rem",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "calc(100% - 2rem)",
                        }}
                      >
                        {file.name}
                      </span>
                      <button
                        onClick={() => handleRemoveFile(index)}
                        style={{
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          padding: "0.25rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "0.25rem",
                          transition: "background-color 0.2s ease",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.backgroundColor = "#F1F5F9")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundColor =
                            "transparent")
                        }
                      >
                        <X size={16} color="#94A3B8" />
                      </button>
                    </div>
                  ))}
                </div>
              )
            )}
          </div>
        </div>

        {/* In-card Progress Bar */}
        {showProgressBar && (
          <div style={{ marginBottom: "1.5rem" }}>
            <div
              style={{
                backgroundColor: "#F8FAFC",
                borderRadius: "0.5rem",
                padding: "0.75rem",
                border: "1px solid #E2E8F0",
              }}
            >
              <div
                style={{
                  marginBottom: "8px",
                  color: "#475569",
                  fontSize: "0.875rem",
                }}
              >
                {uploadStatus === "uploading" && uploadProgress < 100
                  ? "Uploading files..."
                  : uploadStatus === "uploading" && uploadProgress === 100
                  ? "Processing files on server..."
                  : uploadStatus === "success"
                  ? "Upload Complete!"
                  : "Upload Failed"}
              </div>
              <Progress
                percent={uploadProgress}
                size="small"
                status={
                  uploadStatus === "success"
                    ? "success"
                    : uploadStatus === "error"
                    ? "exception"
                    : "active"
                }
                strokeColor="#0A8394"
                style={{
                  width: "100%",
                  margin: 0,
                }}
              />
            </div>
          </div>
        )}

        {/* Compress Option */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            marginBottom: "1.5rem",
          }}
        >
          <input
            type="checkbox"
            id="compress"
            checked={compress}
            onChange={(e) => setCompress(e.target.checked)}
            style={{
              width: "0.75rem",
              height: "0.75rem",
              cursor: "pointer",
            }}
          />
          <label
            htmlFor="compress"
            style={{
              color: "#64748B",
              fontSize: "0.875rem",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            Compress into ZIP file
            {isZipping && (
              <Spin
                size="small"
                style={{
                  color: "#0A8394",
                  marginLeft: "8px",
                }}
              />
            )}
          </label>
        </div>

        {/* Upload Button */}
        <button
          onClick={handleUpload}
          style={{
            width: "100%",
            padding: "0.4rem 0.75rem",
            backgroundColor: "#0A8394",
            color: "#FFFFFF",
            border: "none",
            borderRadius: "0.375rem",
            cursor: "pointer",
            fontSize: "1rem",
            fontWeight: "500",
            transition: "background-color 0.2s ease",
            outline: "none",
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor = "#0E7490")
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = "#0891B2")
          }
        >
          <CloudUpload size={16} style={{ marginRight: "0.5rem" }} />
          Upload files
        </button>
      </div>
    </div>
  );
};

export default FileUpload;
